<template>
    <BaseCardList
        :appearance="appearance"
        :items="videos"
        class="video-card-list"
    >
        <template #noItems>
            <slot name="noVideos">
                No videos found
            </slot>
        </template>
        <template #default="{ item }">
            <VideoCard
                :video="item"
                :is-inline="isSmallScreen || appearance === 'list'"
            />
        </template>

        <template #cta>
            <slot name="cta" />
        </template>
    </BaseCardList>
</template>

<script setup>
import { useMediaQuery } from '@vueuse/core';

const isSmallScreen = useMediaQuery('(max-width: 576px)');

defineProps({
    videos: {
        type: Array,
        required: true
    },
    appearance: {
        type: String,
        default: 'list',
        validator: (value) => ['list', 'hybrid', 'triple'].includes(value)
    }
});
</script>

<style src="./VideoCardList.less" lang="less" />
