<template>
    <component
        :is="to ? defineNuxtLink({}) : 'div'"
        :to="to"
        class="video-card"
        :class="{
            'video-card--inline': isInline,
            'video-card--bg': hasBg === 'always',
            'video-card--bg-mobile': hasBg === 'mobile',
        }"
    >
        <div class="video-card__frame">
            <slot
                name="frame"
                :video="video"
            >
                <NuxtPicture
                    :src="`${config.public.bunnyCDN}/${video.bunny_id}/${video.bunny_thumbnail}`"
                    alt=""
                    placeholder="/images/og/default.png"
                    decoding="async"
                    class="video-card__thumbnail"
                />
                <img 
                    :src="`${config.public.bunnyCDN}/${video.bunny_id}/preview.webp`" 
                    alt=""
                    class="video-card__preview"
                >
                <div v-if="video.duration && !isInline" class="video-card__duration">{{ video.duration }}</div>
            </slot>
        </div>

        <div class="video-card__info">
            <template v-if="isInline">
                <div
                    class="video-card__heading"
                    aria-hidden="true"
                >
                    {{ video.title }}
                </div>

                <BaseUser
                    v-if="video.user"
                    :subtitle="video.user.full_name"
                    :image="video.user?.avatar?.conversions?.webp_64"
                />
            </template>

            <template v-else>
                <BaseUser
                    v-if="video.user"
                    :title="video.title"
                    :image="video.user?.avatar?.conversions?.webp_64"
                >
                    <template #subtitle>
                        {{ video.user.full_name }}
                        <!-- <span
                            v-if="video.views && !isInline"
                            class="video-card__views"
                        >
                            <span aria-hidden="true">•</span>
                            {{ video.views }} {{ video.views > 1 ? 'views' : 'view' }}
                        </span> -->
                    </template>
                </BaseUser>
            </template>
        </div>
    </component>
</template>

<script setup>
const slots = useSlots();
const config = useRuntimeConfig();

const props = defineProps({
    video: {
        type: Object,
        required: true,
        default: null
    },
    isInline: {
        type: Boolean,
        required: false,
        default: false
    },
    hasBg: {
        type: String,
        required: false,
        default: 'none',
        validator: (value) => ['none', 'always', 'mobile'].includes(value)
    }
});

const to = computed(() => {
    return !slots.frame && props.video && props.video.slug ? `/videos/${props.video.slug}` : null;
});
</script>

<style lang="less" src="./VideoCard.less" />
